body,
html {
  padding: 0;
  margin: 0;
}

p-panel.app-panel-padding-0 .p-panel .p-panel-content {
  padding: 0;
}

.app-dashboard-page-header {
  font-size: 1.75rem;
}

// p-panel.app-panel-round-0 .p-panel {
//   border-radius: 0;
// }

p-panel.app-panel-header-padding-0 .p-panel-header {
  padding: 0;
}


.app-bg-clean {
  background-color: #F8F8F8
}

.app-bg-grid {
  background-image: radial-gradient(hsla(0, 0%, 0%, 0.1), 2px, transparent 0);
  background-size: 30px 30px;
  background-position: -14px -14px;
}

.pi::before {
  vertical-align: middle;
}

.card-shadow {
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.05);
}

.section-shadow {
  box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.05);
}

p-autocomplete.ng-pristine.ng-invalid>.p-autocomplete>.p-inputtext {
  border-color: var(--primary-color);
}
